import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import NavigationBar from "../components/Navbar"
import { Helmet } from "react-helmet"
import blogImg from "../img/oops-course.svg"
import { oopsCourseModuleList } from "../data/oopsCourseData"
import NProgress from "nprogress"

import loadable from "@loadable/component"
import LazyLoad from "react-lazy-load"

const ContactForm = loadable(() => import("../components/contactForm"))
const LiveCourses = loadable(() => import("../components/LiveCourseComponent"))
const Footer = loadable(() => import("../components/footer3"))
const SocialMedia = loadable(() => import("../components/social-media"))
const Scroll = loadable(() => import("../components/scroll"))

const LandingPage = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            visibility: { eq: true }
            category: { in: ["criticalideas"] }
          }
        }
      ) {
        nodes {
          frontmatter {
            title
            path
          }
          html
        }
      }
    }
  `)

  useEffect(() => {
    // Call NProgress.start() to display the progress bar
    NProgress.start()
    // Call NProgress.done() when your component is done loading
    NProgress.done()
  }, [])

  const chaptersArray = data.allMarkdownRemark.nodes

  const length = oopsCourseModuleList.length
  const initialShowChaptersList = Array.apply(false, Array(length))

  const initialState = {
    isChapterClicked: false,
    currentModule: null,
    currentChapter: null,
    currentChapterContent: null,
  }

  const [showChaptersList, setShowChaptersList] = useState(
    initialShowChaptersList
  )
  const [chapterState, setChapterState] = useState(initialState)
  const [showOverlay, setShowOverlay] = useState(false)
  const [showForSmallScreen, setShowForSmallScreen] = useState(false)
  const [isExtremeFirst, setIsExtremeFirst] = useState(false)

  let isExtremeLast = false

  const changeOverviewChapter = (module, chapter, path) => {
    const newChapter = chaptersArray.find(
      chapter => chapter.frontmatter.path + "/" === path
    )
    if (module === 1 && chapter === 1) {
      setIsExtremeFirst(true)
    } else {
      setIsExtremeFirst(false)
    }
    if (
      module === oopsCourseModuleList.length &&
      chapter === oopsCourseModuleList[module - 1].chapters.length
    ) {
      isExtremeLast = true
    } else {
      isExtremeLast = false
    }

    setShowForSmallScreen(!showChaptersList)

    setChapterState({
      isChapterClicked: true,
      currentModule: module,
      currentChapter: chapter,
      currentChapterContent: newChapter,
    })
  }

  const onClickModuleHandler = e => {
    const index = Number(e.target.id) - 1

    var newList = Array.apply(false, Array(length))
    newList[index] = !showChaptersList[index]
    setShowChaptersList(newList)
  }

  const showModuleListForSmallScreen = () => {
    setShowOverlay(true)
    setShowForSmallScreen(!showForSmallScreen)
  }

  const gotoThePreviousChapter = () => {
    let m = chapterState.currentModule
    let c = chapterState.currentChapter

    if (c === 1) {
      if (m > 1) {
        const preModule = m - 1
        const preChapter = oopsCourseModuleList[preModule - 1].chapters.length
        const prePath =
          oopsCourseModuleList[preModule - 1].chapters[preChapter - 1].path
        ///console.log("Call")
        changeOverviewChapter(preModule, preChapter, prePath)
      } else {
        console.log("disable button")
      }
    } else {
      const preModule = m
      const preChapter = c - 1
      const prePath =
        oopsCourseModuleList[preModule - 1].chapters[preChapter - 1].path
      changeOverviewChapter(preModule, preChapter, prePath)
    }
  }
  const gotoTheNextChapter = () => {
    let m = chapterState.currentModule
    let c = chapterState.currentChapter

    if (c === oopsCourseModuleList[m - 1].chapters.length) {
      if (m < length) {
        const nextModule = m + 1
        const nextChapter = 1
        const nextPath =
          oopsCourseModuleList[nextModule - 1].chapters[nextChapter - 1].path
        //console.log("Call")
        changeOverviewChapter(nextModule, nextChapter, nextPath)
      } else {
        console.log("disable button")
      }
    } else {
      const nextModule = m
      const nextChapter = c + 1
      const nextPath =
        oopsCourseModuleList[nextModule - 1].chapters[nextChapter - 1].path
      changeOverviewChapter(nextModule, nextChapter, nextPath)
    }
  }

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `auto` })
  }

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title> Crack OOPS Design Interview </title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Master Object-Oriented Programming. We cover OOP concepts, SOLID principles, design patterns, and code examples to help you succeed in technical interviews."
        />
        <meta name="author" content="Shubham Gautam" />
        <meta
          name="title"
          property="og:title"
          content="Object Oriented Programming Course"
        />
      </Helmet>

      <>
        {showOverlay && (
          <div
            className="lg:hidden fixed top-0 left-0 w-screen h-screen z-50"
            role="button"
            tabIndex="0"
            onClick={() => {
              setShowOverlay(false)
              setShowForSmallScreen(false)
            }}
            onKeyDown={event => {
              if (event.key === "Enter" || event.key === "Space") {
                setShowOverlay(false)
                setShowForSmallScreen(false)
              }
            }}
            aria-label="Close Overlay"
          ></div>
        )}

        <div className="">
          <NavigationBar />
        </div>

        <div className="bg-light-white">
          <div className="relative mx-auto px-2 pt-2 sm:px-4 md:px-6 lg:px-8 xl:px-10">
            {!showForSmallScreen && (
              <div
                className="lg:hidden m-2 w-52 h-10 flex items-center justify-center bg-white text-new-purple border border-new-purple rounded cursor-pointer"
                onClick={showModuleListForSmallScreen}
                onKeyDown={event => {
                  if (event.key === "Enter" || event.key === " ") {
                    showModuleListForSmallScreen()
                  }
                }}
                role="button"
                tabIndex="0"
              >
                <svg viewBox="0 0 24 24" className="w-6 h-6">
                  <path
                    fill="#AF4CAB"
                    d="M3 6h18v2H3zm0 5h18v2H3zm0 5h18v2H3z"
                  />
                </svg>
                <p className="ml-2 tracking-wider text-sm">
                  OOPS Course Modules
                </p>
              </div>
            )}
            {showForSmallScreen && (
              <>
                <div className="fixed top-0 left-0 z-60 max-h-screen w-10/12 sm:w-3/5 md:w-2/4 border text-xs sm:text-sm bg-new-white shadow overflow-y-auto max-h-screen lg:hidden duration-3000">
                  <div
                    className="sticky top-0 z-60 lg:hidden m-2 w-12 h-12 flex items-center bg-new-purple justify-center rounded-full cursor-pointer"
                    onClick={showModuleListForSmallScreen}
                    onKeyDown={event => {
                      if (event.key === "Enter" || event.key === " ") {
                        showModuleListForSmallScreen()
                      }
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        fill="#ffffff"
                        d="M18.3 5.71a1 1 0 0 0-1.42 0L12 10.59 7.12 5.71a1 1 0 0 0-1.42 1.42L10.59 12l-4.88 4.88a1 1 0 0 0 1.42 1.42L12 13.41l4.88 4.88a1 1 0 0 0 1.42-1.42L13.41 12l4.89-4.88a1 1 0 0 0 0-1.41z"
                      />
                    </svg>
                  </div>

                  <ul className="mt-6 mb-6 text-left">
                    {oopsCourseModuleList.map(module => (
                      <li>
                        <button
                          className={`flex m-2 px-3 py-2 rounded bg-light-white tracking-wider w-11/12 border border-gray-600 cursor-pointer shadow cursor-pointer ring-1 ring-black ring-opacity-5 transition duration-150 ease-in-out focus:outline-none focus:shadow-outline ${
                            chapterState.currentModule === module.moduleNumber
                              ? "text-new-green"
                              : "text-gray-700"
                          }`}
                          id={`${module.moduleNumber}`}
                          onClick={onClickModuleHandler}
                          onKeyDown={e => {
                            if (e.key === "Enter") {
                              onClickModuleHandler()
                            }
                          }}
                          tabIndex="0"
                        >
                          {module.moduleName}
                        </button>

                        {showChaptersList[module.moduleNumber - 1] &&
                          module.chapters.map(chapter => (
                            <ol className="m-4 text-left">
                              <li>
                                <button
                                  className={`cursor-pointer tracking-wider lg:text-xs xl:text-sm 2xl:text-lg ${
                                    chapterState.isChapterClicked &&
                                    chapterState.currentModule ===
                                      module.moduleNumber &&
                                    chapterState.currentChapter ===
                                      chapter.chapterNumber
                                      ? "text-new-purple"
                                      : "text-new-blue"
                                  }`}
                                  onClick={() => {
                                    changeOverviewChapter(
                                      module.moduleNumber,
                                      chapter.chapterNumber,
                                      chapter.path
                                    )
                                    handleClick()
                                  }}
                                >
                                  <span className="text-new-blue">
                                    {chapter.chapterNumber}.{" "}
                                  </span>
                                  {chapter.chapterName}
                                </button>
                              </li>
                            </ol>
                          ))}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
            <div className="pt-4 lg:grid lg:grid-cols-7 xl:grid-cols-8 select-none">
              <div className="sticky top-5 2xl:top-8 grid lg:col-span-2 rounded self-start flex flex-row justify-center items-center hidden lg:block">
                <h2 className="text-center tracking-wider text-gray-900 text-lg 2xl:text-xl m-2">
                  OOPS Course Modules
                </h2>
                <ul className="overflow-y-auto shadow bg-new-white rounded lg:max-h-extra-small1 xl:max-h-small 2xl:max-h-large text-left">
                  {oopsCourseModuleList.map(module => (
                    <li role="presentation">
                      <button
                        className={`flex tracking-wider lg:py-1 xl:py-2 px-2 lg:text-xs xl:text-sm 2xl:text-lg hover:text-white bg-white border-t w-full hover:bg-new-purple rounded cursor-pointer ${
                          chapterState.currentModule === module.moduleNumber
                            ? "text-new-green"
                            : "text-gray-600"
                        }`}
                        id={`${module.moduleNumber}`}
                        onClick={onClickModuleHandler}
                      >
                        <div className="2xl:mt-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-chevron-down"
                          >
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                        </div>

                        {module.moduleName}
                      </button>
                      {showChaptersList[module.moduleNumber - 1] && (
                        <ol className="m-2 ml-3 text-left">
                          {module.chapters.map(chapter => (
                            <li key={chapter.chapterNumber}>
                              <button
                                className={`lg:text-xs m-1 xl:text-sm tracking-wider 2xl:text-base ${
                                  chapterState.isChapterClicked &&
                                  chapterState.currentModule ===
                                    module.moduleNumber &&
                                  chapterState.currentChapter ===
                                    chapter.chapterNumber
                                    ? "text-new-purple"
                                    : "text-new-blue hover:text-new-green"
                                }`}
                                onClick={() => {
                                  changeOverviewChapter(
                                    module.moduleNumber,
                                    chapter.chapterNumber,
                                    chapter.path
                                  )
                                  handleClick()
                                }}
                              >
                                <span className="text-gray-600">
                                  {chapter.chapterNumber}.{" "}
                                </span>

                                {chapter.chapterName}
                              </button>
                            </li>
                          ))}
                        </ol>
                      )}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="px-4 md:px-6 lg:px-8 xl:px-10 2xl:px-12 lg:grid lg:col-span-5 xl:col-span-6">
                {!chapterState.isChapterClicked && (
                  <div className="pt-2 flex flex-col tracking-wider items-center">
                    <h1 className="tracking-wider leading-relaxed text-xl md:text-2xl xl:text-3xl 2xl:text-4xl text-left">
                      Learn Fundamentals of Object Oriented Programming
                    </h1>

                    <div className="relative mt-4">
                      <div className="relative mx-auto max-w-2xl xl:max-w-4xl 2xl:max-w-5xl">
                        <div className="relative w-full h-full overflow-hidden">
                          <img
                            className="object-cover pointer-events-none w-full h-full sm:w-11/12 sm:h-11/12 2xl:w-10/12 2xl:h-10/12"
                            src={blogImg}
                            alt="Four pillars of object oriented programming"
                          />
                        </div>
                      </div>

                      <h2 className="tracking-wider leading-relaxed mt-4 text-gray-900 text-xl lg:text-2xl 2xl:text-3xl text-left">
                        What will you learn?
                      </h2>
                      <ul className="mt-6 tracking-wider leading-relaxed text-gray-600 text-sm xl:text-base 2xl:text-xl grid gap-4 sm:grid-cols-2">
                        <li className="flex">
                          <svg width="24" height="24" viewBox="0 0 24 24">
                            <path
                              fill="#616161"
                              d="M12 1.206l2.94 5.682 6.568.954-4.747 4.616 1.122 6.539-5.883-3.094-5.883 3.094 1.122-6.539-4.747-4.616 6.568-.954z"
                            />
                          </svg>
                          <p className="ml-2"> Concept of class and object</p>
                        </li>

                        <li className="flex">
                          <svg width="24" height="24" viewBox="0 0 24 24">
                            <path
                              fill="#616161"
                              d="M12 1.206l2.94 5.682 6.568.954-4.747 4.616 1.122 6.539-5.883-3.094-5.883 3.094 1.122-6.539-4.747-4.616 6.568-.954z"
                            />
                          </svg>
                          <p className="ml-2">Four pillars of OOPS</p>
                        </li>

                        <li className="flex">
                          <svg width="24" height="24" viewBox="0 0 24 24">
                            <path
                              fill="#616161"
                              d="M12 1.206l2.94 5.682 6.568.954-4.747 4.616 1.122 6.539-5.883-3.094-5.883 3.094 1.122-6.539-4.747-4.616 6.568-.954z"
                            />
                          </svg>
                          <p className="ml-2">Concept of SOLID principles</p>
                        </li>

                        <li className="flex">
                          <svg width="24" height="24" viewBox="0 0 24 24">
                            <path
                              fill="#616161"
                              d="M12 1.206l2.94 5.682 6.568.954-4.747 4.616 1.122 6.539-5.883-3.094-5.883 3.094 1.122-6.539-4.747-4.616 6.568-.954z"
                            />
                          </svg>
                          <p className="ml-2">Popular design patterns</p>
                        </li>

                        <li className="flex">
                          <svg width="24" height="24" viewBox="0 0 24 24">
                            <path
                              fill="#616161"
                              d="M12 1.206l2.94 5.682 6.568.954-4.747 4.616 1.122 6.539-5.883-3.094-5.883 3.094 1.122-6.539-4.747-4.616 6.568-.954z"
                            />
                          </svg>
                          <p className="ml-2">Modularity and Reusability</p>
                        </li>

                        <li className="flex">
                          <svg width="24" height="24" viewBox="0 0 24 24">
                            <path
                              fill="#616161"
                              d="M12 1.206l2.94 5.682 6.568.954-4.747 4.616 1.122 6.539-5.883-3.094-5.883 3.094 1.122-6.539-4.747-4.616 6.568-.954z"
                            />
                          </svg>
                          <p className="ml-2">Coupling and Cohesion</p>
                        </li>
                      </ul>

                      <div className="mx-auto text-gray-600 text-sm xl:text-base 2xl:text-xl tracking-wider leading-relaxed">
                        <p className="mt-6">
                          The goal of this course is to cover all fundamental
                          concepts important for OOPS design interviews and help
                          learners develop a solid foundation of object-oriented
                          thought processes.
                        </p>

                        <p className="mt-6">
                          We have provided most of the code examples in Java,
                          but in the future, we will also add OOPS content and
                          examples in C++ and Python. Additionally, we will add
                          more content on design patterns, OOAD, low-level
                          design strategy and questions, and more.
                        </p>
                        <div className="mt-6">
                          <ContactForm />
                        </div>
                        <div className="mt-6 ">
                          <Footer />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {chapterState.isChapterClicked && (
                  <div className="relative">
                    {
                      <LazyLoad>
                        <div className="mx-auto max-w-prose xl:max-w-screen-md 2xl:max-w-screen-lg">
                          <h1 className="mb-4 mt-6 text-xl sm:text-2xl tracking-wider xl:text-3xl 2xl:text-4xl leading-7 text-left text-gray-900">
                            {
                              chapterState.currentChapterContent?.frontmatter
                                .title
                            }
                          </h1>

                          <div className="mx-auto">
                            <div
                              className="px-2sm:px-4 mt-6 tracking-wider select-none mx-auto max-w-none prose xl:prose-lg 2xl:prose-xl"
                              dangerouslySetInnerHTML={{
                                __html:
                                  chapterState.currentChapterContent?.html,
                              }}
                            ></div>
                          </div>
                        </div>
                      </LazyLoad>
                    }

                    <LazyLoad>
                      <div className="mt-6 flex flex-row justify-center">
                        <button
                          disabled={isExtremeFirst}
                          className={`m-4 normal-case flex tracking-wider text-sm sm:text-base 2xl:text-lg bg-new-green hover:bg-light-green hover:text-gray-900 transition duration-150 text-white px-4 py-3 rounded ${
                            isExtremeFirst
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                          onClick={() => {
                            if (!isExtremeFirst) {
                              gotoThePreviousChapter()
                              handleClick()
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-left-circle"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <polyline points="12 8 8 12 12 16"></polyline>
                            <line x1="16" y1="12" x2="8" y2="12"></line>
                          </svg>
                          <p className="ml-2">Prev</p>
                        </button>
                        <button
                          disabled={isExtremeLast}
                          className={`m-4 normal-case flex tracking-wider text-sm sm:text-base 2xl:text-lg bg-new-purple hover:bg-light-purple text-white hover:text-gray-900 transition duration-150 px-4 py-3 rounded ${
                            isExtremeLast
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                          onClick={() => {
                            if (!isExtremeLast) {
                              gotoTheNextChapter()
                              handleClick()
                            }
                          }}
                        >
                          <p className="mr-2">Next</p>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right-circle"
                          >
                            <circle cx="12" cy="12" r="10" />
                            <polyline points="12 16 16 12 12 8" />
                            <line x1="8" y1="12" x2="16" y2="12" />
                          </svg>
                        </button>
                      </div>
                    </LazyLoad>

                    <Scroll showBelow={250} />

                    <SocialMedia />
                    <LiveCourses />

                    <div className="mt-4">
                      <ContactForm />
                    </div>

                    <div className="mt-4">
                      <Footer />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </React.Fragment>
  )
}

export default LandingPage
